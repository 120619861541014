@import "~bootstrap-icons/font/bootstrap-icons.css";

:root {
  --sidenav-width: 360px;
  --sidenav-collapsed-width: 20px;
  --sidenav-transition-duration: 400ms;
}

* {
  font-family: "Open Sans", calibri;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  //overflow-y: clip;
}

.h5 {
  font-size: 16px;
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

.alcon-color {
  background-color: #003493;
}

.alcon-text-color {
  color: #003493;
}

// Over ride the boostrap defailt css manually
.navbar {
  background-color: #003493;

  >a {
    color: aliceblue;
  }

  padding: 0;
}

// Over ride the boostrap defailt css manually
.nav-link {
  color: aliceblue;
}

// Over ride the boostrap defailt css manually
.nav-link:hover {}

// Over ride the boostrap defailt css manually
.row {
  margin-left: 0px;
  margin-right: 0px;
}

// Over ride the boostrap defailt css manually
.row>* {
  padding: 0;
  margin-left: 0px;
  margin-right: 0px;
}

// Over ride the boostrap defailt css manually to fit to screen
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-right: 0;
  padding-left: 0;
}

.big-circle {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.medium-circle {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.fw-light {
  color: #003493;
}

.fw-bold {
  color: #003493;
}

.dropdown-item:hover .dropdown-item:focus {
  background-color: #7d99cc;
}

.card {
  border-radius: 0px;
}

.btn-edit,
.btn-edit:hover,
.btn-edit:focus,
.btn-edit:active,
.btn-edit.active {
  color: #003595;
  width: auto;
  height: 40px;
  padding: 5px 24px 8px 24px;
  border-radius: 1000px;
  border: 2px;
  gap: 6px;

  border: 2px solid #003595;
  background: white;
}

.btn-home-edit,
.btn-home-edit:hover,
.btn-home-edit:focus,
.btn-home-edit:active,
.btn-home-edit.active {
  color: #003595;
  // width: 80%;
  height: 48px;
  padding: 12px 28px 12px 28px;
  border-radius: 1000px;
  border: 2px;
  gap: 6px;
  border: 2px solid #003595;
  background: white;

  font-family: 'Open Sans';
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0.02em;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}

.btn-submit,
.btn-submit:hover,
.btn-submit:focus,
.btn-submit:active,
.btn-submit.active {
  color: #fff;
  width: auto;
  height: 40px;
  padding: 5px 24px 8px 24px;
  border-radius: 1000px;
  border: 2px;
  gap: 6px;
  background: #003595;
  border: 2px solid #003595;
}

.btn-cancel,
.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active,
.btn-cancel.active {
  color: #fff;
  width: auto;
  height: 40px;
  padding: 5px 24px 8px 24px;
  border-radius: 1000px;
  border: 2px;
  gap: 6px;
  background: #a7a7a7;
  border: 2px solid #a7a7a7;
}

.btn-reset,
.btn-reset:hover,
.btn-reset:focus,
.btn-reset:active,
.btn-reset.active {
  color: #003595;
  width: auto;
  height: 40px;
  padding: 8px 24px 8px 24px;

  border: none;
  gap: 6px;
  text-decoration: underline;
}

// @media (min-width: 1400px) {

//   .container-xxl,
//   .container-xl,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 1500px;
//   }
// }

// @media (min-width: 1200px) {

//   .container-xl,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 1300px;
//   }
// }

// @media (min-width: 992px) {

//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 1060px;
//   }
// }

// @media (min-width: 768px) {

//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 720px;
//   }
// }

// @media (min-width: 576px) {

//   .container-sm,
//   .container {
//     max-width: 540px;
//   }
// }

.ring {
  z-index: 999;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border: 3px solid #3377e8;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #3377e8;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #3377e8;
  box-shadow: 0 0 20px #3377e8(0, 0, 0, 0.5);
}

// .ring:before
// {
//   content:'';
//   position:absolute;
//   top:-3px;
//   left:-3px;
//   width:100%;
//   height:100%;
//   border:3px solid transparent;
//   border-top:3px solid green;
//   border-right:3px solid green;
//   border-radius:50%;
//   animation:animateC 2s linear infinite;
// }
.span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

.span:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #003595;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px #003595;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.loader {
  margin: auto;
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid #3377e8;
  width: 200px;
  height: 200px;
  animation: spinner 4s linear infinite;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// input[type="tel"] {
//   /* Your styles here */
//   border: 1px solid red;
//   border-radius: 5px;
//   width:100px;
//   text-align: left !important;

// }

input[type="tel"] {
  // border: 1px solid red;
  border-radius: 5px;
  width: 205px;
  text-align: left !important;
  padding-left: 10px !important;
  font-size: medium;
}

.iti {
  padding-left: 0px;
}

.iti--allow-dropdown {
  padding-left: 0px;
}

.separate-dial-code {
  padding-left: 0px;
}

.ti-sdc-4 {
  padding-left: 0px;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type="text"],
.iti--allow-dropdown input[type="tel"],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type="text"],
.iti--separate-dial-code input[type="tel"] {
  padding-right: 6px;
  padding-left: 0px;
  margin-left: 0;
}

.iti {
  left: 120px;

  .separate-dial-code {
    padding-left: 0;
  }
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
  margin-left: -100px;
}

.input-tel1 {
  // margin: 0px 4px;
  // padding: 6px 12px;
  border-style: none;
  border-width: 1px;
  // border-color: #841010;
  border-radius: 4px;
  font-size: 28px;
  height: 51px;
  border-radius: 8px !important;
  border: 1px solid #ced4da;
  left: -15px;
  width: 170px !important;
}

.iti__selected-flag {
  height: 51px;
  border-radius: 8px !important;
  display: block;
  width: 80px !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.modal-dialog {
  top: 40%;
}

.close-button {
  position: absolute;
  top: 1;
  right: 0;
  margin: 15px;
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Flexbox container for the header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  /* Add padding for spacing */
  background-color: rgba(0, 53, 149, 1);
  height: 3.125rem;
  color: white;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1000;
  /* Ensure it appears above other elements */
}

/* Style for the header title */
.header__title {
  margin: 0;
  /* Remove default margin */
}

/* Style for the images */
.header__image {
  padding-right: 1.125rem;
  padding-left: 1.75rem;
}

.coh-style-a-header-right-button {
  color: #003595;
  height: 50px;
  display: block;
  font-size: 0.875rem;
  line-height: 14px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.625rem;
  font-weight: 400;
  font-family: open sans, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-image: url(https://dev-okta.myalcon.com/au/sites/g/files/rbvwei2636/files/svg-images/arrow-right.svg);
  background-position: right 0.625rem center;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  width: 8.25rem;
}

/* Media query for mobile view */
@media only screen and (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
  .btn-home-edit,
.btn-home-edit:hover,
.btn-home-edit:focus,
.btn-home-edit:active,
.btn-home-edit.active {
  font-size: 12px !important;
}




  .header__image {
    margin-top: 10px;
    /* Add spacing between elements in mobile view */
  }
}

.navbar {
  background-color: #003595;
}

.head-title {
  text-align: center;
  font-family: "Open Sans";
  font-size: 40px ;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  padding: 40px 0;
  margin:0;
}

@media (min-width: 769px) and (max-width: 1023px) {
.head-title{
  font-size: 35px;
}
}

@media (max-width: 768px) {
  .head-title{
    font-size: 28px;
  }
  }

.sub-header {
  font-family: "Open Sans", calibri;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color:  #003595;
  padding-left:16px;
  /* 140% */
}

.back-link {
  padding-left: 16px;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  /* 266.667% */
  text-decoration-line: underline;
}

@media only screen and (max-device-width: 1024px) {

  .btn-home-edit,
.btn-home-edit:hover,
.btn-home-edit:focus,
.btn-home-edit:active,
.btn-home-edit.active {
  font-size: 12px !important;
  padding: 10px 15px !important;
}
}

@media (min-width: 834px) and (max-width: 1000px) {

  .btn-home-edit,
.btn-home-edit:hover,
.btn-home-edit:focus,
.btn-home-edit:active,
.btn-home-edit.active {
  font-size: 12px !important;
  padding: 8px !important;
}
}

@media (min-width: 1001px) and (max-width: 1440px) {

  .btn-home-edit,
.btn-home-edit:hover,
.btn-home-edit:focus,
.btn-home-edit:active,
.btn-home-edit.active {
  padding: 12px 20px !important;
}
}

